<template>
  <div class="card">
    <div class="card-body tab-content">
      <h6>USER PROFILE</h6>
      <hr />

      <div class="row">
        <div class="col-md-3 border-right">
          <div class="d-flex flex-column align-items-center text-center p-3 pb-5" >
            <img class="rounded-circle mt-2 img-fluid" src="//via.placeholder.com/360/360" />
            <span class="font-weight-bold">{{ currentUser.name }}</span>
            <span class="text-black-50">{{ currentUser.email }}</span>
          </div>
        </div>
        <div class="col-md-9 border-right">

          <!-- email -->
          <b-form-group
            label="Email"
            label-for=""
            label-size="sm"
          >
          <b-input type="email" placeholder="Enter email" :value="currentUser.email" disabled />
          </b-form-group>

          <!-- key select -->
          <b-form-group
            v-if="userAccounts.length > 1"
            label="Key Account"
            label-for=""
          >
            <multiselect 
              v-model="keyAccount"
              trackBy="account"
              label="name"
              size="sm"
              :options="userAccounts"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              placeholder="Select to filter..."
              @input="selectApiKey"
            ></multiselect>
          </b-form-group>
          

          <div class="mt-5 text-center">
            <button class="btn btn-primary profile-button" type="button">
              Save Profile
            </button>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: "UserProfile",

  data() {
    return {
      keyAccount: null,
      // Fake data
      active_category: "",
      open_questions: [],

      categories: [
        { id: "test", icon: "cart", label: "Using the dashboard" },
        { id: "test2", icon: "truck", label: "Contact and Support" },
        { id: "test3", icon: "box", label: "Some other stuff" },
        { id: "test4", icon: "credit-card", label: "Some empty stuff" },
        { id: "test5", icon: "chat", label: "Some other empty stuff" },
      ],

      faqs: [
        {
          category: "test",
          question: "Lorem ipsum dolor sit.",
          answer: "Lorem ipsum dolor sit amet consectetur.",
        },
        {
          category: "test",
          question: "Lorem ipsum dolor sit amet.",
          answer: "Lorem ipsum dolor sit amet consectetur.",
        },
        {
          category: "test2",
          question: "Lorem ipsum dolor sit amet consectetur.",
          answer: "Lorem ipsum dolor sit amet consectetur.",
        },
        {
          category: "test2",
          question: "Lorem ipsum dolor sit amet.",
          answer: "Lorem ipsum dolor sit amet consectetur.",
        },
        {
          category: "test3",
          question: "Lorem ipsum dolor.",
          answer: "Lorem ipsum dolor sit <i>amet</i> consectetur.",
        },
      ],
    };
  },
  computed: {
    // USER MODULE
    ...mapGetters('user', [
      'currentUser',
      'userHasRole',
      'userAccounts'
    ]),
  },
  methods: {
    ...mapActions('user', [
      'setFakeApiKey',
    ]),

    selectApiKey(key) {
      this.setFakeApiKey(key);
    },

    // FAKE
    setCategory(id) {
      this.active_category = null;
      this.open_questions = [];
      //setTimeout(() => {
      this.active_category = id;
      //}, 300)
    },
    toggleQuestionOpen(index) {
      if (this.open_questions.includes(index)) {
        this.open_questions = this.open_questions.filter((x) => x !== index);
      } else {
        this.open_questions.push(index);
      }
    },
    faqsInCategory(cat) {
      return this.faqs.filter((x) => x.category == cat);
    },
  },
};
</script>

<style lang="scss" scoped>
.question .answer {
  display: none;
}
.question.open .answer {
  display: block;
}

.row.small-gutters {
  margin-right: -5px;
  margin-left: -5px;
  & > .col,
  & > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
  }
}

/* VERSION 2 */
.category {
  cursor: pointer;
}

li {
  font-size: 1.1rem;
  & > div {
    font-size: 0.9rem;
  }
}

@media (min-width: 992px) {
  .category {
    font-size: 1.2em;
  }
  svg {
    font-size: 2.4rem;
  }
}
/* END VERSION 2 */

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>